import React from "react";

import HomepageHero from "../components/homepageHero";
import Layout from "../components/layout";
// import Insights from "../components/insights";
import SEO from "../components/seo";
import HomepageOurWork from "../components/homepageOurWork";
import Community from "../components/community";

export default function IndexPage() {
  return (
    <Layout hero={<HomepageHero />}>
      <SEO title="Home" />

      <Community />

      {/* <Insights /> */}

      <HomepageOurWork />
    </Layout>
  );
}
