import React from "react";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Heading from "./heading";
import Section from "./section";

import homepageHero from "../styles/homepageHero.module.css";
import components from "../styles/components.module.css";

const useStyles = makeStyles({
  root: {
    fontSize: "1.1rem",
    fontWeight: 700,
    lineHeight: "35.64px",
    padding: "18px 30px"
  }
});

export default function HomepageHero() {
  const { root } = useStyles();

  return (
    <Section className={homepageHero.container}>
      <div className={homepageHero.inner}>
        <Heading className={components.heroText}>
          Coders Against COVID is a volunteer group that builds tech solutions
          to address the most pressing needs of those affected by COVID-19.
        </Heading>

        <div className={homepageHero.actions}>
          <Button
            className={homepageHero.action}
            classes={{ root }}
            variant="contained"
            href="https://findcovidtesting.com/"
            rel="noopener"
            target="_blank"
            color="primary"
            size="large"
            disableElevation
          >
            Find COVID-19 Testing
          </Button>

          <Button classes={{ root }} disabled>
            or
          </Button>

          <Button
            className={homepageHero.action}
            classes={{ root }}
            variant="text"
            href="https://github.com/codersagainstcovidorg/covid19testing-backend"
            rel="noopener"
            target="_blank"
            color="primary"
            size="large"
            disableElevation
          >
            Get the data
          </Button>
        </div>
      </div>
    </Section>
  );
}
