import React from "react";

import Logos from "./logos";
import Section from "./section";
import Subheading from "./subheading";

import homepageOurWork from "../styles/homepageOurWork.module.css";

import dhhs from "../assets/images/press_logos/Seal_of_the_United_States_Department_of_Health_and_Human_Services.svg";
import dod from "../assets/images/press_logos/United_States_Department_of_Defense_Seal.svg";
import fema from "../assets/images/press_logos/FEMA_logo.svg";
import apple from "../assets/images/press_logos/Apple_logo_grey.svg";
import aws from "../assets/images/press_logos/Amazon_Web_Services_Logo.svg";
import google from "../assets/images/press_logos/Google_2015_logo.svg";
import doxim from "../assets/images/press_logos/Doximity.svg";
import chealth from "../assets/images/press_logos/CH_identity_Black horizontal.svg";

const logos = [
  [fema, "FEMA"],
  [dhhs, "United States Department of Health and Human Services"],
  [dod, "United States Department of Defense"],
  [google, "Google"],
  [aws, "Amazon Web Services"],
  [apple, "Apple"],
  [doxim, "Doximity"],
  [chealth, "CollectiveHealth"],
];

export default function HomepageOurWork() {
  return (
    <Section className={homepageOurWork.container}>
      <div className={homepageOurWork.inner}>
        <Subheading>Stimulate collaboration</Subheading>

        <p className={homepageOurWork.copy}>
          These are just a few of the organizations that have supported our
          efforts, sought our guidance, and/or leveraged our free and open
          source software:
        </p>

        <Logos logoData={logos} />
      </div>
    </Section>
  );
}
