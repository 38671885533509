import classNames from "classnames";
import React from "react";

import logos from "../styles/logos.module.css";

export default function Logos(props) {
  const { logoData, color } = props;

  return logoData ? (
    <div className={logos.container}>
      {logoData.map(([src, name]) => (
        <div className={logos.logo} key={name}>
          <img
            src={src}
            className={classNames(logos.image, !color && logos.grayscale)}
            alt={name}
          />
        </div>
      ))}
    </div>
  ) : null;
}

Logos.defaultProps = {
  color: false
};
