import React from "react";
import Carousel from "nuka-carousel";

import Link from "./link";
import Section from "./section";
import Subheading from "./subheading";

import findcovidtesting from "../assets/images/content/findcovidtesting.png";
import community from "../styles/community.module.css";
import { customProperties } from "../styles/postcss.module.css";

export default function Community() {
  return (
    <Section>
      <div className={community.container}>
        <Subheading className={community.heading}>Foster Community</Subheading>
        <Carousel
          easing="easeQuadOut"
          edgeEasing="easeQuadOut"
          heightMode="max"
          renderCenterLeftControls={() => null}
          renderCenterRightControls={() => null}
          disableEdgeSwiping
          defaultControlsConfig={{
            pagingDotsStyle: {
              fill: customProperties["--brand-color"],
              transform: "translate3d(-50%, 200%, 0)"
            }
          }}
        >
          <div className={community.slide}>
            <div className={community.slideHeading}>
              <h4 className={community.title}>
                <Link href="https://findcovidtesting.com" external>
                  findcovidtesting.com
                </Link>
              </h4>
              <p className={community.description}>
                The first national directory of COVID-19 testing locations in
                the United States
              </p>
            </div>

            <div className={community.slideContent}>
              <div className={community.thumbnail}>
                <Link href="https://findcovidtesting.com" external>
                  <img
                    src={findcovidtesting}
                    alt="Screenshot of findcovidtesting.com"
                    className={community.image}
                  />
                </Link>
                <p className={community.caption}>
                  <Link href="https://findcovidtesting.com" external>
                    findcovidtesting.com
                  </Link>{" "}
                  was first released on March 21, 2020. Originally based on a
                  dataset we developed internally, and shared openly with the
                  world. Today, this React web application is powered by the
                  GISCorps dataset - the single source of truth for testing
                  location information.
                </p>
              </div>

              <div className={community.copy}>
                <p>
                  <Link href="https://findcovidtesting.com" external>
                    findcovidtesting.com
                  </Link>{" "}
                  is a website for anyone seeking information about COVID-19
                  testing locations. It is comprehensive, unbiased, and free to
                  use. Unlike profit-motivated competitors,{" "}
                  <Link href="https://findcovidtesting.com" external>
                    findcovidtesting.com
                  </Link>{" "}
                  is not monetized in any way, and directly contributes to an
                  open dataset that serves FEMA, public health agencies, and
                  NGOs involved in the COVID-19 response effort.
                </p>
                <p className={community.cta}>
                  <Link to="/our-projects/findcovidtesting">
                    »&nbsp;Learn more
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </Section>
  );
}
